import {
  Container,
  Select,
  Text,
  Heading,
  Divider,
  VStack,
  Center,
  FormLabel,
  FormControl,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  AlertDialogCloseButton,
  ModalCloseButton,
  Input,
  Textarea,
  Spinner,
  Stack,
  Radio,
  RadioGroup,
  Box,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import {
  useFetchInstrumentNames,
  useUserData,
} from "../../hooks/data/calendar";
import { LoadingSpinner } from "../../components/loading-spinner";
import { ErrorCard } from "../../components/error-card";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import theme from "../../utils/theme";
import { IoMdRefresh } from "react-icons/io";
import { auth } from "../../utils/auth";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import "../styles.css";

const { DateTime } = require("luxon");

export default function Calendar() {
  const { register } = useForm();

  // Maintain calendar in pacific time
  const currentDateTime = DateTime.now();
  const pacificTime = currentDateTime
    .setZone("America/Los_Angeles")
    .toLocaleString({
      timeZoneName: "short",
      hour12: false,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  const [, timePart] = pacificTime.split(", ");
  const [time] = timePart.split(" ");
  let [hours, ,] = time.split(":");
  if (hours === "24") {
    hours = 0;
  }
  const currentHour = hours + ":00";

  const [userName, setUserName] = useState(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const curUserInfo = auth();
    setUserName(curUserInfo.username);
  }, []);
  const { userData, isLoadingUserData, isErrorUserData } =
    useUserData(userName);

  const [instrument, setInstrument] = useState("");
  const [calendarColor, setCalendarColor] = useState("rgb(231, 230, 228)");
  const [instrumentAvailability, setInstrumentAvailability] = useState(true);
  const [instrumentMaxHoursPerWeekPeak, setInstrumentMaxHoursPerWeekPeak] =
    useState(null);
  const [
    instrumentMaxHoursPerWeekOffPeak,
    setInstrumentMaxHoursPerWeekOffPeak,
  ] = useState(null);
  const [instrumentMaxHoursPerDay, setInstrumentMaxHoursPerDay] =
    useState(null);
  const [instrumentExpirationTime, setInstrumentExpirationTime] =
    useState(null);
  const [userAccessStatus, setUserAccessStatus] = useState(null);
  const [offPeakPermission, setOffPeakPermission] = useState("");
  const [lastLoginDate, setLastLoginDate] = useState(null);
  const [calendarRangeStartDate, setCalendarRangeStartDate] = useState("");
  const [calendarRangeEndDate, setCalendarRangeEndDate] = useState("");
  const [events, setEvents] = useState([]);
  const { instruments, isLoadingInstruments, isErrorInstruments } =
    useFetchInstrumentNames(userName);
  const [eventInfoModalisOpen, setEventInfoModalisOpen] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState({
    extendedProps: {},
  });
  const [eventCreationConfirmModalisOpen, setEventCreationConfirmModalisOpen] =
    useState(false);
  const [
    multipleEventCreationModalIsOpen,
    setMultipleEventCreationModalIsOpen,
  ] = useState(false);
  const [selectedMultipleDates, setSelectedMultipleDates] = useState([
    new Date(),
    new Date(),
  ]);
  const [selectedMultipleEventTime, setSelectedMultipleEventTime] = useState([
    dayjs("10:00", "HH:mm"),
    dayjs("14:00", "HH:mm"),
  ]);
  const [multipleEventCreationIsLoading, setMultipleEventCreationIsLoading] =
    useState(false);
  const [multipleEventAdditionLogData, setMultipleEventAdditionLogData] =
    useState([]);
  const [eventCreationData, setEventCreationData] = useState(null);
  const [eventCreationSelectInfo, setEventCreationSelectInfo] = useState(null);
  const [eventCreationIsLoading, setEventCreationIsLoading] = useState(false);
  const [newEventOwner, setNewEventOwner] = useState("");
  const [newEventComments, setNewEventComments] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState([]);
  const calendarRef = useRef(null);
  const [currentCalendarView, setCurrentCalendarView] = useState(
    isMobile ? "timeGridDay" : "timeGridWeek"
  );
  const [peakOffPeak, setPeakOffPeak] = useState({ daysOfWeek: [] });
  const [deleteConfirmationModalIsOpen, setDeleteConfirmationModalIsOpen] =
    useState(false);
  const [changeEventOwnerModalIsOpen, setChangeEventOwnerModalIsOpen] =
    useState(false);
  const [editEventCommentsModalIsOpen, setEditEventCommentsModalIsOpen] =
    useState(false);
  const [deleteConfirmationModalMessage, setDeleteConfirmationModalMessage] =
    useState("");
  const [
    cancellationRequestEmailModalIsOpen,
    setCancellationRequestEmailModalIsOpen,
  ] = useState(false);
  const [cancellationRequestEmailSubject, setCancellationRequestEmailSubject] =
    useState("");
  const [cancellationRequestEmailBody, setCancellationRequestEmailBody] =
    useState("");
  const [emailEventOwnersModalIsOpen, setEmailEventOwnersModalIsOpen] =
    useState(false);
  const [emailEventOwnersEmails, setEmailEventOwnersEmails] = useState("");
  const [emailEventOwnersEmailSubject, setEmailEventOwnersEmailSubject] =
    useState("");
  const [emailEventOwnersEmailBody, setEmailEventOwnersEmailBody] =
    useState("");
  const [totalHoursByOwner, setTotalHoursByOwner] = useState({});
  const [selectedEventCreationUserType, setselectedEventCreationUserType] =
    useState("user");
  const [
    selectedEventOwnerChangeTypeChange,
    setSelectedEventOwnerChangeTypeChange,
  ] = useState("user");

  useEffect(() => {
    // this is a janky solution to retain the modal till an event is being created. When the event is created, the 'events' state variable changes. When it does, we can set the loading to false and close the modal
    setEventCreationIsLoading(false);
    setEventCreationConfirmModalisOpen(false);
    setChangeEventOwnerModalIsOpen(false);
    setEditEventCommentsModalIsOpen(false);

    setMultipleEventCreationIsLoading(false);
  }, [events]);

  if (isLoadingInstruments || isLoadingUserData) return <LoadingSpinner />;
  if (isErrorInstruments || isErrorUserData) {
    return (
      <Container maxW="container.lg">
        <Center>
          <ErrorCard />
        </Center>
      </Container>
    );
  }

  function formatISOTimeString(d, t) {
    let newTime = new Date();
    //Date
    newTime.setFullYear(parseInt(d[0]), parseInt(d[1]) - 1, parseInt(d[2]));
    //Time
    newTime.setHours(parseInt(t[0]));
    newTime.setMinutes(parseInt(t[1]));
    newTime.setSeconds(0);
    return newTime;
  }

  function calculateTotalHours(events) {
    const result = {};

    events.forEach((event) => {
      const eventOwner = event.extendedProps.eventOwner;
      const advisor = event.extendedProps.advisor;
      const startTime = new Date(event.start);
      const endTime = new Date(event.end);
      const duration = (endTime - startTime) / (1000 * 60 * 60); // Calculate duration in hours

      if (!result[eventOwner]) {
        result[eventOwner] = { totalHours: 0, advisor: advisor };
      }

      result[eventOwner].totalHours += duration;
    });

    return result;
  }

  const updateEvents = async (instrument, startTime, endTime, typeOfView) => {
    const params = new URLSearchParams();
    params.append("InstrumentName", instrument);
    params.append("StartTime", startTime);
    params.append("EndTime", endTime);

    const url = `${
      process.env.REACT_APP_API_URL
    }/calendar/fetch-calendar-events?${params.toString()}`;
    const response = await fetch(url);
    const data = await response.json();

    let fetchedEvents = [];
    for (let event of data) {
      let d_start = event.StartTime.split("T")[0].split("-");
      let t_start = event.StartTime.split("T")[1].split(":");
      let t_end = event.EndTime.split("T")[1].split(":");
      let startTime = formatISOTimeString(d_start, t_start);
      let endTime = formatISOTimeString(d_start, t_end);

      let eventJSON = {
        start: startTime,
        end: endTime,
        extendedProps: {
          eventId: event.Id,
          eventOwner: event.Subject,
          emailId: event.EmailId,
          advisor: event.Advisor,
          telephone: event.Telephone,
          formattedStartTime: event.FormattedStartTime,
          formattedEndTime: event.FormattedEndTime,
          instrument: event.Instrument,
          restricted: event.Restricted,
          comments: event.Comments_Calendar,
        },
        backgroundColor: event.color,
      };

      if (event.IsAllDayEvent === 1) {
        eventJSON.title = event.Description;
        eventJSON.allDay = true;
      } else {
        eventJSON.title = event.Subject;
      }
      // Set events to editable ie resize and movable only when class is admin (1) or staff (2)
      if (userData.UserClass === 1 || userData.UserClass === 2) {
        eventJSON.editable = true;
      }
      fetchedEvents.push(eventJSON);
    }
    // Change businesshours for peak and offpeak time.
    // If it is a month view then set all days as peak hours, this is just visual and doesnt affect anything since a user is unable to create events in this view.
    // Get the correct business hours for each day of the week. This will be the latest update made which is <= the current days date. For example if the business hours of a given instrument was updated on 10 July 2023, then 12 July 2023, then on 20 July 2023 and the current date is 13 July 2023 then we need the business hours from 12 July 2023. (the latest date which is <= current date).
    // NOTE: we only need the business hours data for week and day views.

    if (typeOfView === "dayGridMonth") {
      setPeakOffPeak({
        daysOfWeek: [1, 2, 3, 4, 5, 6, 0],
      });
    } else {
      // get business hours data
      const params = new URLSearchParams();
      params.append("InstrumentName", instrument);
      params.append("StartDate", startTime);

      const url = `${
        process.env.REACT_APP_API_URL
      }/calendar/fetch-business-hours?${params.toString()}`;
      const response = await fetch(url);
      const businessHoursData = await response.json();

      // remove the days which have All day events. These are offpeak for the entire day and overrides the business hours of this day
      const businessHoursDaysOfWeek = [1, 2, 3, 4, 5, 6, 0];
      for (let i = 0; i < fetchedEvents.length; i++) {
        if (fetchedEvents[i].allDay === true) {
          const dayToRemove = fetchedEvents[i].start.getDay();
          const indexToRemove = businessHoursDaysOfWeek.indexOf(dayToRemove);
          if (indexToRemove !== -1) {
            businessHoursDaysOfWeek.splice(indexToRemove, 1);
          }
        }
      }
      // create a map of dayOfWeekStartTimeName -> day of week and same for EndTime. This is done since we get the businesshours for all days of the week for a particular date. But we only need the business hours which apply to that date. For example 0 -> Sunday so we fetch just the sunday.
      const weekNameToDayCodeAllDayOffPeak = {
        0: "SundayOffPeakAllDay",
        1: "MondayOffPeakAllDay",
        2: "TuesdayOffPeakAllDay",
        3: "WednesdayOffPeakAllDay",
        4: "ThursdayOffPeakAllDay",
        5: "FridayOffPeakAllDay",
        6: "SaturdayOffPeakAllDay",
      };
      const weekNameToDayCodeStart = {
        0: "SundayFrom",
        1: "MondayFrom",
        2: "TuesdayFrom",
        3: "WednesdayFrom",
        4: "ThursdayFrom",
        5: "FridayFrom",
        6: "SaturdayFrom",
      };
      const weekNameToDayCodeEnd = {
        0: "SundayTo",
        1: "MondayTo",
        2: "TuesdayTo",
        3: "WednesdayTo",
        4: "ThursdayTo",
        5: "FridayTo",
        6: "SaturdayTo",
      };

      // remove the days which have All day events from the business hours data.
      for (let i = 0; i < 7; i++) {
        if (businessHoursData[i][weekNameToDayCodeAllDayOffPeak[i]] === 1) {
          const indexToRemove = businessHoursDaysOfWeek.indexOf(i);
          if (indexToRemove !== -1) {
            businessHoursDaysOfWeek.splice(indexToRemove, 1);
          }
        }
      }

      let newBusinessHours = [];
      for (let i = 0; i < businessHoursDaysOfWeek.length; i++) {
        newBusinessHours.push({
          daysOfWeek: [businessHoursDaysOfWeek[i]],
          startTime:
            businessHoursData[businessHoursDaysOfWeek[i]][
              weekNameToDayCodeStart[businessHoursDaysOfWeek[i]]
            ],
          endTime:
            businessHoursData[businessHoursDaysOfWeek[i]][
              weekNameToDayCodeEnd[businessHoursDaysOfWeek[i]]
            ],
        });
      }

      if (newBusinessHours.length === 0) {
        setPeakOffPeak({ daysOfWeek: [] });
      } else {
        setPeakOffPeak(newBusinessHours);
      }
    }
    // Calculate total hours by owner
    const hoursByOwner = calculateTotalHours(fetchedEvents);
    // Additional logic for business hours...

    setEventInfoModalisOpen(false);
    setAlertIsOpen(false);
    setDeleteConfirmationModalIsOpen(false);
    setEvents(fetchedEvents);
    setTotalHoursByOwner(hoursByOwner);
    return;
  };

  const updateHomePageEvents = async (startTime, endTime, typeOfView) => {
    // set extended reservations button to disabled at home page
    const extendedReservationButton = document.querySelector(
      ".fc-multipleReservationsButton-button"
    );
    if (extendedReservationButton) {
      extendedReservationButton.disabled = true;
    }

    const params = new URLSearchParams();
    params.append("UserName", userName);
    params.append("StartTime", startTime);
    params.append("EndTime", endTime);

    const url = `${
      process.env.REACT_APP_API_URL
    }/calendar/fetch-home-page-calendar-events?${params.toString()}`;
    const response = await fetch(url);
    const data = await response.json();

    let fetchedEvents = [];
    for (let event of data) {
      let d_start = event.StartTime.split("T")[0].split("-");
      let t_start = event.StartTime.split("T")[1].split(":");
      let t_end = event.EndTime.split("T")[1].split(":");
      let startTime = formatISOTimeString(d_start, t_start);
      let endTime = formatISOTimeString(d_start, t_end);

      let eventJSON = {
        start: startTime,
        end: endTime,
        extendedProps: {
          eventId: event.Id,
          eventOwner: event.Subject,
          emailId: event.EmailId,
          advisor: event.Advisor,
          telephone: event.Telephone,
          formattedStartTime: event.FormattedStartTime,
          formattedEndTime: event.FormattedEndTime,
          instrument: event.Instrument,
          restricted: event.Restricted,
          comments: event.Comments_Calendar,
        },
        backgroundColor: event.color,
      };

      if (event.IsAllDayEvent === 1) {
        eventJSON.title = event.Description;
        eventJSON.allDay = true;
      } else {
        eventJSON.title = event.Subject;
      }
      fetchedEvents.push(eventJSON);
    }

    setPeakOffPeak({
      daysOfWeek: [1, 2, 3, 4, 5, 6, 0],
      startTime: "00:00",
      endTime: "23:59",
    });
    setEventInfoModalisOpen(false);
    setAlertIsOpen(false);
    setDeleteConfirmationModalIsOpen(false);
    setEvents(fetchedEvents);

    return;
  };

  const handleInstrumentChange = async (event) => {
    const selectedInstrument = event.target.value;

    if (selectedInstrument === "") {
      setInstrument("");
      setCalendarColor("rgb(231, 230, 228)");
      setInstrumentAvailability(true);
      setInstrumentMaxHoursPerWeekPeak(null);
      setInstrumentMaxHoursPerWeekOffPeak(null);
      setInstrumentMaxHoursPerDay(null);
      setInstrumentExpirationTime(null);
      setUserAccessStatus(null);
      setOffPeakPermission("");
      setLastLoginDate(null);
      const extendedReservationButton = document.querySelector(
        ".fc-multipleReservationsButton-button"
      );
      extendedReservationButton.disabled = true;
      updateHomePageEvents(
        calendarRangeStartDate,
        calendarRangeEndDate,
        currentCalendarView
      );
      return;
    }

    setInstrument(selectedInstrument);
    setCalendarColor(
      event.target.options[event.target.selectedIndex].dataset.color
    );
    if (
      event.target.options[event.target.selectedIndex].dataset.availability ===
      "0"
    ) {
      setInstrumentAvailability(false);
    } else {
      setInstrumentAvailability(true);
    }
    setInstrumentMaxHoursPerWeekPeak(
      event.target.options[event.target.selectedIndex].dataset
        .maxhoursperweekpeak
    );
    setInstrumentMaxHoursPerWeekOffPeak(
      event.target.options[event.target.selectedIndex].dataset
        .maxhoursperweekoffpeak
    );
    setInstrumentMaxHoursPerDay(
      event.target.options[event.target.selectedIndex].dataset.maxhoursperday
    );
    setInstrumentExpirationTime(
      event.target.options[event.target.selectedIndex].dataset.expirationtime
    );
    setUserAccessStatus({
      AllowAccess: Number(
        event.target.options[event.target.selectedIndex].dataset.allowaccess
      ),
      AccesDenied: Number(
        event.target.options[event.target.selectedIndex].dataset.accesdenied
      ),
    });
    setOffPeakPermission(
      event.target.options[event.target.selectedIndex].dataset.permission
    );
    setLastLoginDate(
      event.target.options[event.target.selectedIndex].dataset.lastlogindate
    );
    const extendedReservationButton = document.querySelector(
      ".fc-multipleReservationsButton-button"
    );
    if (
      event.target.options[event.target.selectedIndex].dataset
        .extendedreservation === "1" &&
      extendedReservationButton
    ) {
      extendedReservationButton.disabled = false;
    } else if (extendedReservationButton) {
      extendedReservationButton.disabled = true;
    }
    updateEvents(
      selectedInstrument,
      calendarRangeStartDate,
      calendarRangeEndDate,
      currentCalendarView
    );
    return;
  };

  const handleCalendarViewChange = (dateInfo) => {
    // get the visible range on calendar and fetch the data for these days
    let start = dateInfo.view.activeStart;
    let end = dateInfo.view.activeEnd;

    // get the events of current week even if on day view
    if (dateInfo.view.type === "timeGridDay") {
      const startOfWeek = new Date(dateInfo.view.activeStart);
      const weekDay = dateInfo.view.activeStart.getDay();
      const diffMonday =
        startOfWeek.getDate() - weekDay + (weekDay === 0 ? -6 : 1);
      const monday = new Date(startOfWeek.setDate(diffMonday));
      const startOfWeek2 = new Date(dateInfo.view.activeStart);
      const weekDay2 = dateInfo.view.activeStart.getDay();
      const diffSunday =
        startOfWeek2.getDate() + (weekDay2 === 0 ? 0 : 7 - weekDay2);
      const sunday = new Date(startOfWeek2.setDate(diffSunday + 1));

      start = monday;
      end = sunday;
    }

    const startTime = `${start.getFullYear()}-${
      start.getMonth() + 1
    }-${start.getDate()}`;
    const endTime = `${end.getFullYear()}-${
      end.getMonth() + 1
    }-${end.getDate()}`;
    setCalendarRangeStartDate(startTime);
    setCalendarRangeEndDate(endTime);
    setCurrentCalendarView(dateInfo.view.type);

    if (instrument === "") {
      updateHomePageEvents(startTime, endTime, dateInfo.view.type);
      return;
    }
    updateEvents(instrument, startTime, endTime, dateInfo.view.type);
    return;
  };

  const handleDateSelect = async (selectInfo) => {
    // If month view, then email everyone with an event on this day
    if (selectInfo.view.type === "dayGridMonth") {
      setEmailEventOwnersEmails("");
      setEmailEventOwnersEmailSubject("");
      setEmailEventOwnersEmailBody("");
      handleEmailAllEventOwners(selectInfo.start, selectInfo.end);
      return;
    }

    // Perform checks before adding an event

    // Check 1) number of weeks since last login must be less than expirationTime
    let weeksPassed = -1;
    if (lastLoginDate) {
      const lastLoginDateDate = new Date(lastLoginDate);
      const currentDate = new Date();
      const timeDiff = currentDate - lastLoginDateDate;
      weeksPassed = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 7));
    }

    // if the weekspassed >= expiration time, then check the access status for the user.
    // If the access is valid ie AllowAccess=1 and AccesDenied=0, need to revoke access by setting AllowAccess=0 and AccesDenied=1. Else if access was already revoked, then simply inform the user.
    if (weeksPassed >= instrumentExpirationTime) {
      if (
        userAccessStatus.AllowAccess === 1 &&
        userAccessStatus.AccesDenied === 0
      ) {
        // revoke access
        const params4 = new URLSearchParams();
        params4.append("UserName", userName);
        params4.append("InstrumentName", instrument);
        const requestUpdateUserAccess = `${
          process.env.REACT_APP_API_URL
        }/calendar/update-user-access?${params4.toString()}`;
        const responseUpdateUserAccess = await fetch(requestUpdateUserAccess, {
          method: "PUT",
        });
        console.log(responseUpdateUserAccess);

        setAlertTitle("⚠ Could not create reservation");
        setAlertMessage([
          `Last login was over ${parseInt(
            instrumentExpirationTime / 4
          )} months. Please contact Staff for training details.`,
        ]);
        setAlertIsOpen(true);
        selectInfo.view.calendar.unselect();
        return;
      }

      if (userAccessStatus.AllowAccess === 0) {
        setAlertTitle("⚠ Could not create reservation");
        setAlertMessage([
          `Last login was over ${parseInt(
            instrumentExpirationTime / 4
          )} months. Please contact Staff for training details.`,
        ]);
        setAlertIsOpen(true);
        selectInfo.view.calendar.unselect();
        return;
      }
    }

    // Check 2) Handle Off-Peak permissions and limits how many hours can be booked for peak, offpeak and per day. Userclass 1 and 2 have no limits
    if (userData.UserClass > 2) {
      const reservationErrors = [];

      const usersEventsThisDay = calendarRef.current
        .getApi()
        .getEvents()
        .filter((event) => {
          return (
            event.start.getDate() === selectInfo.start.getDate() &&
            event.start.getMonth() === selectInfo.start.getMonth() &&
            event.start.getFullYear() === selectInfo.start.getFullYear() &&
            event.title === userName
          );
        });
      let minutesBookedByUserToday = 0;
      for (let i = 0; i < usersEventsThisDay.length; i++) {
        minutesBookedByUserToday +=
          (usersEventsThisDay[i].end - usersEventsThisDay[i].start) /
          (1000 * 60);
      }
      minutesBookedByUserToday +=
        (selectInfo.end - selectInfo.start) / (1000 * 60);
      if (minutesBookedByUserToday > instrumentMaxHoursPerDay * 60) {
        reservationErrors.push(
          `You cannot book more than ${instrumentMaxHoursPerDay} hours per day.`
        );
      }

      const usersEventsThisWeek = calendarRef.current
        .getApi()
        .getEvents()
        .filter((event) => {
          return event.title === userName;
        });
      // peakOffPeak defines the business hours, but it is in a format which fullcalendar accepts. Change it into a hashmap of day -> hours for quick and easy access.
      const startOfWeek = new Date();
      const yearStartOfWeek = parseInt(calendarRangeStartDate.split("-")[0]);
      const monthStartOfWeek = parseInt(calendarRangeStartDate.split("-")[1]);
      const dateStartOfWeek = parseInt(calendarRangeStartDate.split("-")[2]);
      startOfWeek.setFullYear(yearStartOfWeek);
      startOfWeek.setMonth(monthStartOfWeek - 1);
      startOfWeek.setDate(dateStartOfWeek);
      startOfWeek.setHours(0);
      startOfWeek.setMinutes(0);
      startOfWeek.setSeconds(0);
      startOfWeek.setMilliseconds(0);
      const businessHoursHashmap = peakOffPeak.reduce((acc, item) => {
        const daysOfWeek = item.daysOfWeek;
        const { startTime, endTime } = item;
        daysOfWeek.forEach((day) => {
          acc[day] = { startTime, endTime };
        });
        return acc;
      }, {});
      for (const bh in businessHoursHashmap) {
        if (bh === 0) {
          const startOfThisBusinessHours = new Date(startOfWeek);
          const endOfThisBusinessHours = new Date(startOfWeek);
          startOfThisBusinessHours.setDate(
            startOfThisBusinessHours.getDate() + 6
          );
          endOfThisBusinessHours.setDate(endOfThisBusinessHours.getDate() + 6);
          let businessHoursStartHour = parseInt(
            businessHoursHashmap[bh].startTime.split(":")[0]
          );
          let businessHoursStartMinutes = parseInt(
            businessHoursHashmap[bh].startTime.split(":")[1]
          );
          let businessHoursEndHour = parseInt(
            businessHoursHashmap[bh].endTime.split(":")[0]
          );
          let businessHoursEndMinutes = parseInt(
            businessHoursHashmap[bh].endTime.split(":")[1]
          );
          startOfThisBusinessHours.setHours(businessHoursStartHour);
          startOfThisBusinessHours.setMinutes(businessHoursStartMinutes);
          endOfThisBusinessHours.setHours(businessHoursEndHour);
          endOfThisBusinessHours.setMinutes(businessHoursEndMinutes);
          businessHoursHashmap[bh].start = startOfThisBusinessHours;
          businessHoursHashmap[bh].end = endOfThisBusinessHours;
        } else {
          const startOfThisBusinessHours = new Date(startOfWeek);
          const endOfThisBusinessHours = new Date(startOfWeek);
          startOfThisBusinessHours.setDate(
            startOfThisBusinessHours.getDate() + (bh - 1)
          );
          endOfThisBusinessHours.setDate(
            endOfThisBusinessHours.getDate() + (bh - 1)
          );
          let businessHoursStartHour = parseInt(
            businessHoursHashmap[bh].startTime.split(":")[0]
          );
          let businessHoursStartMinutes = parseInt(
            businessHoursHashmap[bh].startTime.split(":")[1]
          );
          let businessHoursEndHour = parseInt(
            businessHoursHashmap[bh].endTime.split(":")[0]
          );
          let businessHoursEndMinutes = parseInt(
            businessHoursHashmap[bh].endTime.split(":")[1]
          );
          startOfThisBusinessHours.setHours(businessHoursStartHour);
          startOfThisBusinessHours.setMinutes(businessHoursStartMinutes);
          endOfThisBusinessHours.setHours(businessHoursEndHour);
          endOfThisBusinessHours.setMinutes(businessHoursEndMinutes);
          businessHoursHashmap[bh].start = startOfThisBusinessHours;
          businessHoursHashmap[bh].end = endOfThisBusinessHours;
        }
      }

      let totalPeakMinutes = 0;
      let totalOffPeakMinutes = 0;

      // add the peak and offpeak hours being added by this new event
      const dayOfWeekOfEvent = selectInfo.start.getDay();
      if (!businessHoursHashmap.hasOwnProperty(dayOfWeekOfEvent)) {
        totalOffPeakMinutes +=
          (selectInfo.end - selectInfo.start) / (1000 * 60);
      } else {
        if (selectInfo.start < businessHoursHashmap[dayOfWeekOfEvent].start) {
          if (selectInfo.end <= businessHoursHashmap[dayOfWeekOfEvent].start) {
            totalOffPeakMinutes +=
              (selectInfo.end - selectInfo.start) / (1000 * 60);
          } else {
            if (selectInfo.end <= businessHoursHashmap[dayOfWeekOfEvent].end) {
              totalOffPeakMinutes +=
                (businessHoursHashmap[dayOfWeekOfEvent].start -
                  selectInfo.start) /
                (1000 * 60);
              totalPeakMinutes +=
                (selectInfo.end -
                  businessHoursHashmap[dayOfWeekOfEvent].start) /
                (1000 * 60);
            } else {
              totalOffPeakMinutes +=
                (businessHoursHashmap[dayOfWeekOfEvent].start -
                  selectInfo.start) /
                (1000 * 60);
              totalPeakMinutes +=
                (businessHoursHashmap[dayOfWeekOfEvent].end -
                  businessHoursHashmap[dayOfWeekOfEvent].start) /
                (1000 * 60);
              totalOffPeakMinutes +=
                (selectInfo.end - businessHoursHashmap[dayOfWeekOfEvent].end) /
                (1000 * 60);
            }
          }
        }
        if (
          selectInfo.start >= businessHoursHashmap[dayOfWeekOfEvent].start &&
          selectInfo.start < businessHoursHashmap[dayOfWeekOfEvent].end
        ) {
          if (selectInfo.end <= businessHoursHashmap[dayOfWeekOfEvent].end) {
            totalPeakMinutes +=
              (selectInfo.end - selectInfo.start) / (1000 * 60);
          } else {
            totalPeakMinutes +=
              (businessHoursHashmap[dayOfWeekOfEvent].end - selectInfo.start) /
              (1000 * 60);
            totalOffPeakMinutes +=
              (selectInfo.end - businessHoursHashmap[dayOfWeekOfEvent].end) /
              (1000 * 60);
          }
        }
        if (selectInfo.start >= businessHoursHashmap[dayOfWeekOfEvent].end) {
          totalOffPeakMinutes +=
            (selectInfo.end - selectInfo.start) / (1000 * 60);
        }
      }

      if (offPeakPermission === "Peak" && totalOffPeakMinutes > 0) {
        setAlertTitle("⚠ Could not create reservation");
        setAlertMessage(["You do not have Off-Peak permissions."]);
        setAlertIsOpen(true);
        selectInfo.view.calendar.unselect();
        return;
      }

      for (const e of usersEventsThisWeek) {
        const dayOfWeekOfEvent = e.start.getDay();
        // if the day is not in businessHoursHashmap, this means that entire day is offpeak
        if (!businessHoursHashmap.hasOwnProperty(dayOfWeekOfEvent)) {
          totalOffPeakMinutes += (e.end - e.start) / (1000 * 60);
        } else {
          // An event which starts before the business hours, can end before business hours begin, or end during business hours or end after business hours have ended
          if (e.start < businessHoursHashmap[dayOfWeekOfEvent].start) {
            if (e.end <= businessHoursHashmap[dayOfWeekOfEvent].start) {
              totalOffPeakMinutes += (e.end - e.start) / (1000 * 60);
            } else {
              if (e.end <= businessHoursHashmap[dayOfWeekOfEvent].end) {
                totalOffPeakMinutes +=
                  (businessHoursHashmap[dayOfWeekOfEvent].start - e.start) /
                  (1000 * 60);
                totalPeakMinutes +=
                  (e.end - businessHoursHashmap[dayOfWeekOfEvent].start) /
                  (1000 * 60);
              } else {
                totalOffPeakMinutes +=
                  (businessHoursHashmap[dayOfWeekOfEvent].start - e.start) /
                  (1000 * 60);
                totalPeakMinutes +=
                  (businessHoursHashmap[dayOfWeekOfEvent].end -
                    businessHoursHashmap[dayOfWeekOfEvent].start) /
                  (1000 * 60);
                totalOffPeakMinutes +=
                  (e.end - businessHoursHashmap[dayOfWeekOfEvent].end) /
                  (1000 * 60);
              }
            }
          }
          // An event which starts within the business hours, can end before the business hours end or end after business hours have ended
          if (
            e.start >= businessHoursHashmap[dayOfWeekOfEvent].start &&
            e.start < businessHoursHashmap[dayOfWeekOfEvent].end
          ) {
            if (e.end <= businessHoursHashmap[dayOfWeekOfEvent].end) {
              totalPeakMinutes += (e.end - e.start) / (1000 * 60);
            } else {
              totalPeakMinutes +=
                (businessHoursHashmap[dayOfWeekOfEvent].end - e.start) /
                (1000 * 60);
              totalOffPeakMinutes +=
                (e.end - businessHoursHashmap[dayOfWeekOfEvent].end) /
                (1000 * 60);
            }
          }
          // An event which starts after the end of business hours can only end outside of business hours
          if (e.start >= businessHoursHashmap[dayOfWeekOfEvent].end) {
            totalOffPeakMinutes += (e.end - e.start) / (1000 * 60);
          }
        }
      }

      if (totalPeakMinutes > instrumentMaxHoursPerWeekPeak * 60) {
        reservationErrors.push(
          `You cannot book more than ${instrumentMaxHoursPerWeekPeak} hours of peak time per week.`
        );
      }
      if (totalOffPeakMinutes > instrumentMaxHoursPerWeekOffPeak * 60) {
        reservationErrors.push(
          `You cannot book more than ${instrumentMaxHoursPerWeekOffPeak} hours of offpeak time per week.`
        );
      }

      if (reservationErrors.length > 0) {
        setAlertTitle("⚠ Could not create reservation");
        setAlertMessage(reservationErrors);
        setAlertIsOpen(true);
        selectInfo.view.calendar.unselect();
        return;
      }
    }

    let endTimeCorrected = selectInfo.end;
    // If end time is 12am of next day, then set it to 11:59:59 of current day
    if (selectInfo.end.getHours() === 0 && selectInfo.end.getMinutes() === 0) {
      endTimeCorrected = new Date(selectInfo.end);
      endTimeCorrected.setMinutes(endTimeCorrected.getMinutes() - 1);
    }

    // All checks passed, continue to create an event
    let data = {
      UserName: userName,
      InstrumentName: instrument,
      StartTime: selectInfo.start,
      EndTime: endTimeCorrected,
    };

    setEventCreationData(data);
    setEventCreationSelectInfo(selectInfo);
    setEventCreationConfirmModalisOpen(true);
    return;
  };

  const handleEventCreationOwnerUserName = (event) => {
    const data = eventCreationData;
    data.UserName = event.target.value;
    data.IsAdvisor = selectedEventCreationUserType === "advisor";
    setEventCreationData(data);
  };

  const handleEventComments = (event) => {
    const data = eventCreationData;
    data.Comments = event.target.value;
    setEventCreationData(data);
  };

  const handleEventCreationConfirmation = async () => {
    setEventCreationIsLoading(true);
    const responseCreateCalendarEvent = await fetch(
      `${process.env.REACT_APP_API_URL}/calendar/add-calendar-event`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(eventCreationData),
      }
    );

    if (responseCreateCalendarEvent.ok) {
      updateEvents(
        instrument,
        calendarRangeStartDate,
        calendarRangeEndDate,
        currentCalendarView
      );
    }
  };

  const handleEventCreationCancel = () => {
    eventCreationSelectInfo.view.calendar.unselect();
    setEventCreationConfirmModalisOpen(false);
    setselectedEventCreationUserType("user");
    return;
  };

  const handleMultipleEventCreationCancel = () => {
    setMultipleEventCreationModalIsOpen(false);
    setSelectedMultipleDates([new Date(), new Date()]);
    setSelectedMultipleEventTime([
      dayjs("10:00", "HH:mm"),
      dayjs("14:00", "HH:mm"),
    ]);
    setMultipleEventAdditionLogData([]);
    return;
  };

  const handleMultipleDayEventReset = () => {
    setSelectedMultipleDates([new Date(), new Date()]);
    setSelectedMultipleEventTime([
      dayjs("10:00", "HH:mm"),
      dayjs("14:00", "HH:mm"),
    ]);
    setMultipleEventAdditionLogData([]);
    return;
  };

  const handleCreateMultipleEvents = async () => {
    setMultipleEventCreationIsLoading(true);

    const selectedMultipleEventStartTime = `${
      selectedMultipleEventTime[0].$H
    }:${selectedMultipleEventTime[0].$m < 10 ? "0" : ""}${
      selectedMultipleEventTime[0].$m
    }`;
    const selectedMultipleEventEndTime = `${selectedMultipleEventTime[1].$H}:${
      selectedMultipleEventTime[1].$m < 10 ? "0" : ""
    }${selectedMultipleEventTime[1].$m}`;

    let data = {
      User: userName,
      selectedInstruments: [instrument],
      selectedDates: selectedMultipleDates,
      fromTime: selectedMultipleEventStartTime,
      toTime: selectedMultipleEventEndTime,
    };

    const responseCreateMultipleCalendarEvents = await fetch(
      `${process.env.REACT_APP_API_URL}/multiple-reservation/add-multiple-events`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    let eventAdditionLog;
    if (responseCreateMultipleCalendarEvents.ok) {
      eventAdditionLog = await responseCreateMultipleCalendarEvents.json();
      updateEvents(
        instrument,
        calendarRangeStartDate,
        calendarRangeEndDate,
        currentCalendarView
      );
    }

    // populate the status table
    const startDate = new Date(data.selectedDates[0]);
    const endDate = new Date(data.selectedDates[1]);
    const currentDate = new Date(startDate);
    let idx = 0;
    const statuses = [];
    while (currentDate <= endDate) {
      const dateOfEvent = `${currentDate.getFullYear()}-${
        currentDate.getMonth() + 1
      }-${currentDate.getDate()}`;

      for (const instr of data.selectedInstruments) {
        statuses.push({
          date: dateOfEvent,
          instrument: instr,
          status: eventAdditionLog[idx],
        });
        idx++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setMultipleEventAdditionLogData(statuses);
    return;
  };

  const handleEventClick = (eventClickInfo) => {
    setSelectedEventData(eventClickInfo.event);
    setNewEventOwner(eventClickInfo.event.extendedProps.eventOwner);
    setNewEventComments(eventClickInfo.event.extendedProps.comments);
    setEventInfoModalisOpen(true);
  };

  const handleDelete = async () => {
    // If userclass == 1 (admin), then allow deletion of all events. For past or currently active events, show a warning.
    // If userclass == 2 (staff), then allow deletion of currently active events (with a warning) and future events.
    // Else userclass >= 3, do not allow past or currently active event deletion. For events within 24 hours, check restricted. Open request cancellation email for restricted. Else allow delete with confirmation.

    const startTime = selectedEventData.start;
    let endTime;
    // Allday events do not have a end time, so set endTime to startTime for such events.
    if (selectedEventData.allDay === true) {
      endTime = selectedEventData.start;
    } else {
      endTime = selectedEventData.end;
    }
    const currentTime = new Date();

    // admin
    if (userData.UserClass === 1) {
      if (currentTime.getTime() >= startTime.getTime()) {
        if (currentTime.getTime() <= endTime.getTime()) {
          setDeleteConfirmationModalIsOpen(true);
          setDeleteConfirmationModalMessage(
            "NOTE: Event is currently ongoing!"
          );
          return;
        } else {
          setDeleteConfirmationModalIsOpen(true);
          setDeleteConfirmationModalMessage("NOTE: Event is in the past.");
          return;
        }
      } else {
        setDeleteConfirmationModalIsOpen(true);
        setDeleteConfirmationModalMessage("");
        return;
      }
    }

    // staff
    if (userData.UserClass === 2) {
      if (currentTime.getTime() >= startTime.getTime()) {
        if (currentTime.getTime() <= endTime.getTime()) {
          setDeleteConfirmationModalIsOpen(true);
          setDeleteConfirmationModalMessage(
            "NOTE: Event is currently ongoing!"
          );
          return;
        } else {
          setAlertTitle("⚠ Unable to Delete Reservation");
          setAlertMessage(["Cannot delete past events"]);
          setAlertIsOpen(true);
          return;
        }
      } else {
        setDeleteConfirmationModalIsOpen(true);
        setDeleteConfirmationModalMessage("");
        return;
      }
    }

    if (currentTime.getTime() >= startTime.getTime()) {
      if (currentTime.getTime() <= endTime.getTime()) {
        setAlertTitle("⚠ Unable to Delete Reservation");
        setAlertMessage(["Cannot delete ongoing events."]);
        setAlertIsOpen(true);
        return;
      } else {
        setAlertTitle("⚠ Unable to Delete Reservation");
        setAlertMessage(["Cannot delete past events"]);
        setAlertIsOpen(true);
        return;
      }
    }

    // if it is not a restricted event, then allow delete
    if (selectedEventData.extendedProps.restricted === "No") {
      setDeleteConfirmationModalIsOpen(true);
      setDeleteConfirmationModalMessage("");
      return;
    }

    // if time till event is >24 hours, allow delete
    const diff = startTime.getTime() - currentTime.getTime();
    const diffHours = Math.ceil(diff / (1000 * 60 * 60));

    if (diffHours > 24) {
      setDeleteConfirmationModalIsOpen(true);
      setDeleteConfirmationModalMessage("");
      return;
    }

    // else open the email dialog to request deletion
    setCancellationRequestEmailBody(
      `Hi, I want to delete the reservation made under the email ID ${userData.Email} for ${selectedEventData.extendedProps.instrument} at ${selectedEventData.extendedProps.formattedStartTime}`
    );
    setCancellationRequestEmailSubject("Reservation Cancellation Permission");
    setCancellationRequestEmailModalIsOpen(true);
    return;
  };

  const handleChangeEventOwner = async () => {
    setChangeEventOwnerModalIsOpen(true);
    return;
  };

  const handleEditEventComments = async () => {
    setEditEventCommentsModalIsOpen(true);
    return;
  };

  const handleFinalDelete = async () => {
    const responseDeleteCalendarEvent = await fetch(
      `${process.env.REACT_APP_API_URL}/calendar/delete-calendar-event?EventId=${selectedEventData.extendedProps.eventId}`,
      {
        method: "DELETE",
      }
    );
    if (responseDeleteCalendarEvent.status === 200) {
      selectedEventData.remove();
      setEventInfoModalisOpen(false);
      setAlertIsOpen(false);
      setDeleteConfirmationModalIsOpen(false);
    }
  };

  const handleEventOwnerChangeConfirmation = async () => {
    setEventCreationIsLoading(true);
    const data = {
      Id: selectedEventData.extendedProps.eventId,
      NewEventOwner: newEventOwner,
      IsAdvisor: selectedEventOwnerChangeTypeChange === "advisor",
    };

    const responseUpdateCalendarEvent = await fetch(
      `${process.env.REACT_APP_API_URL}/calendar/update-calendar-event-owner`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (responseUpdateCalendarEvent.ok) {
      if (instrument === "") {
        updateHomePageEvents(
          calendarRangeStartDate,
          calendarRangeEndDate,
          currentCalendarView
        );
        return;
      }
      updateEvents(
        instrument,
        calendarRangeStartDate,
        calendarRangeEndDate,
        currentCalendarView
      );
    }
  };

  const handleChangeEventCommentsConfirmation = async () => {
    setEventCreationIsLoading(true);
    const data = {
      Id: selectedEventData.extendedProps.eventId,
      NewEventComments: newEventComments,
    };

    const responseUpdateCalendarEvent = await fetch(
      `${process.env.REACT_APP_API_URL}/calendar/update-calendar-event-comments`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (responseUpdateCalendarEvent.ok) {
      if (instrument === "") {
        updateHomePageEvents(
          calendarRangeStartDate,
          calendarRangeEndDate,
          currentCalendarView
        );
        return;
      }
      updateEvents(
        instrument,
        calendarRangeStartDate,
        calendarRangeEndDate,
        currentCalendarView
      );
    }
  };

  const handleSendCancellationRequestEmail = async () => {
    let data = {
      InstrumentName: selectedEventData.extendedProps.instrument,
      UserEmail: userData.Email,
      EventStart: selectedEventData.start.toString(),
      EventCalendarId: selectedEventData.extendedProps.eventId,
      EmailSubject: cancellationRequestEmailSubject,
      EmailBody: cancellationRequestEmailBody,
    };

    const fetchResult = await fetch(
      `${process.env.REACT_APP_API_URL}/calendar/cancellation-email-cemma-staff`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (fetchResult.status === 200) {
      setAlertTitle("📧 Email sent!");
      setAlertMessage([]);
      setAlertIsOpen(true);
    }

    closeCancellationRequestionEmailModal(false);
    return;
  };

  const handleEmailAllEventOwners = (startTime, endTime) => {
    const eventsInRange = calendarRef.current
      .getApi()
      .getEvents()
      .filter((event) => {
        const eventStart = event.start;

        return eventStart >= startTime && eventStart < endTime;
      });

    if (eventsInRange.length === 0) {
      setAlertTitle("⚠ Unable to Email");
      setAlertMessage(["There are no events on this date."]);
      setAlertIsOpen(true);
      return;
    }

    const eventOwnersEmailsSet = new Set();
    for (let i = 0; i < eventsInRange.length; i++) {
      eventOwnersEmailsSet.add(eventsInRange[i].extendedProps.emailId);
    }
    eventOwnersEmailsSet.delete(userData.Email);

    if (eventOwnersEmailsSet.size === 0) {
      setAlertTitle("⚠ Unable to Email");
      setAlertMessage(["Only you have events on this date."]);
      setAlertIsOpen(true);
      return;
    }

    let eventOwnersEmails = "";
    eventOwnersEmailsSet.forEach((element) => {
      eventOwnersEmails += element + ";";
    });

    setEmailEventOwnersEmails(eventOwnersEmails);
    setEmailEventOwnersModalIsOpen(true);
    return;
  };

  const handleEmailEventOwner = () => {
    setEmailEventOwnersEmails("");
    setEmailEventOwnersEmailSubject("");
    setEmailEventOwnersEmailBody("");
    setEmailEventOwnersEmails(selectedEventData.extendedProps.emailId);
    setEmailEventOwnersModalIsOpen(true);
    return;
  };

  const handleSendEmailToEventOwners = async () => {
    let data = {
      UserEmail: userData.Email,
      EventOwnersEmails: `${emailEventOwnersEmails};`,
      EmailSubject: emailEventOwnersEmailSubject,
      EmailBody: emailEventOwnersEmailBody,
    };

    const fetchResult = await fetch(
      `${process.env.REACT_APP_API_URL}/calendar/email-event-owners`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (fetchResult.status === 200) {
      setAlertTitle("📧");
      setAlertMessage(["Email sent!"]);
      setAlertIsOpen(true);
    }

    closeEmailAllEventOwnersModal(false);
    return;
  };

  const handleSelectAllow = (selectInfo) => {
    // This function defines when and where the calendar disallows creation of events

    // Home page calendar doesnt allow creation of events
    if (instrument === "") {
      return false;
    }

    // Do not allow creation of events in the past if the userclass > 1 ie only admins can do this
    // Note, this should only affects day and week modes. Doesnt affect month mode
    const currentDateTime = new Date();
    if (
      userData.UserClass > 1 &&
      selectInfo.start < currentDateTime &&
      currentCalendarView !== "dayGridMonth"
    ) {
      return false;
    }

    // Do not allow multiple day events, overlaps with other events or all day events
    const events = calendarRef.current.getApi().getEvents();
    if (
      currentCalendarView !== "dayGridMonth" &&
      handleMultipleDayEventValidation(selectInfo.start, selectInfo.end) ===
        false
    ) {
      return false;
    }
    const isOverlap = events.some((existingEvent) => {
      return (
        (selectInfo.start >= existingEvent.start &&
          selectInfo.start < existingEvent.end) ||
        (selectInfo.end > existingEvent.start &&
          selectInfo.end <= existingEvent.end) ||
        (selectInfo.start <= existingEvent.start &&
          selectInfo.end > existingEvent.start)
      );
    });
    if (
      currentCalendarView !== "dayGridMonth" &&
      isOverlap &&
      selectInfo.start !== selectInfo.end
    ) {
      return false;
    }
    // disable creating allday events
    if (selectInfo.allDay === true && currentCalendarView !== "dayGridMonth") {
      return false;
    }

    return true;
  };

  const handleMultipleDayEventValidation = (start, end) => {
    if (start.getDate() !== end.getDate()) {
      const validEnd = new Date(start);
      validEnd.setHours(0);
      validEnd.setMinutes(0);
      validEnd.setSeconds(0);
      validEnd.setMilliseconds(0);
      validEnd.setDate(validEnd.getDate() + 1);

      if (end.getTime() === validEnd.getTime()) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const closeEventInfoModal = () => {
    setEventInfoModalisOpen(false);
  };

  const closeAlert = () => {
    setAlertIsOpen(false);
  };

  const closeDeleteConfirmationModal = () => {
    setDeleteConfirmationModalIsOpen(false);
  };

  const closeChangeEventOwnerModal = () => {
    setChangeEventOwnerModalIsOpen(false);
    setSelectedEventOwnerChangeTypeChange("user");
    return;
  };

  const closeEditEventCommentsModal = () => {
    setEditEventCommentsModalIsOpen(false);
    return;
  };

  const closeCancellationRequestionEmailModal = () => {
    setCancellationRequestEmailModalIsOpen(false);
  };

  const handleMultipleEventCreation = async () => {
    // Perform checks before adding an event

    // Check 1) number of weeks since last login must be less than expirationTime
    let weeksPassed = -1;
    if (lastLoginDate) {
      const lastLoginDateDate = new Date(lastLoginDate);
      const currentDate = new Date();
      const timeDiff = currentDate - lastLoginDateDate;
      weeksPassed = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 7));
    }

    // if the weekspassed >= expiration time, then check the access status for the user.
    // If the access is valid ie AllowAccess=1 and AccesDenied=0, need to revoke access by setting AllowAccess=0 and AccesDenied=1. Else if access was already revoked, then simply inform the user.
    if (weeksPassed >= instrumentExpirationTime) {
      if (
        userAccessStatus.AllowAccess === 1 &&
        userAccessStatus.AccesDenied === 0
      ) {
        // revoke access
        const params4 = new URLSearchParams();
        params4.append("UserName", userName);
        params4.append("InstrumentName", instrument);
        const requestUpdateUserAccess = `${
          process.env.REACT_APP_API_URL
        }/calendar/update-user-access?${params4.toString()}`;
        const responseUpdateUserAccess = await fetch(requestUpdateUserAccess, {
          method: "PUT",
        });
        console.log(responseUpdateUserAccess);

        setAlertTitle("⚠ Could not create reservation");
        setAlertMessage([
          `Last login was over ${parseInt(
            instrumentExpirationTime / 4
          )} months. Please contact Staff for training details.`,
        ]);
        setAlertIsOpen(true);
        return;
      }

      if (userAccessStatus.AllowAccess === 0) {
        setAlertTitle("⚠ Could not create reservation");
        setAlertMessage([
          `Last login was over ${parseInt(
            instrumentExpirationTime / 4
          )} months. Please contact Staff for training details.`,
        ]);
        setAlertIsOpen(true);
        return;
      }
    }
    setMultipleEventCreationModalIsOpen(true);
  };

  const handleRefresh = () => {
    if (instrument === "") {
      updateHomePageEvents(
        calendarRangeStartDate,
        calendarRangeEndDate,
        currentCalendarView
      );
      return;
    }
    updateEvents(
      instrument,
      calendarRangeStartDate,
      calendarRangeEndDate,
      currentCalendarView
    );
    return;
  };

  const closeEmailAllEventOwnersModal = () => {
    setEmailEventOwnersModalIsOpen(false);
  };

  const handleEventEdit = async (info) => {
    // Events are only editable for admin and staff
    // Since there are no permission checks for admin or staff, doesnt require any check to change an event
    // If this has to be added for any other class, then the permissions like peak/offpeak, total hours etc will have to be verified

    let endTimeCorrected = info.event.end;
    if (info.event.end.getHours() === 0 && info.event.end.getMinutes() === 0) {
      endTimeCorrected = new Date(info.event.end);
      endTimeCorrected.setMinutes(endTimeCorrected.getMinutes() - 1);
    }

    const data = {
      Id: info.event.extendedProps.eventId,
      StartTime: info.event.start,
      EndTime: endTimeCorrected,
    };

    const responseUpdateCalendarEvent = await fetch(
      `${process.env.REACT_APP_API_URL}/calendar/update-calendar-event`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (responseUpdateCalendarEvent.ok) {
      updateEvents(
        instrument,
        calendarRangeStartDate,
        calendarRangeEndDate,
        currentCalendarView
      );
    }
  };

  const formatHours = (hours) => {
    // Get the integer part of the hours (whole hours)
    const wholeHours = Math.floor(hours);

    // Calculate the remaining minutes based on the fractional part
    const remainingMinutes = Math.round((hours - wholeHours) * 60);

    // Format the hours and minutes
    const formattedHours = `${wholeHours}h ${remainingMinutes}m`;

    return formattedHours;
  };

  const convertTableToCSV = () => {
    const csvData = [];

    // Add header row for total hours and date range
    csvData.push(["Calendar Hours Summary"]);
    csvData.push(["Instrument: " + instrument]);
    csvData.push([
      "From: " + calendarRangeStartDate,
      "To: " + calendarRangeEndDate,
    ]); // If you have actual dates, you can replace these with the variables

    // Add the table headers
    const headerRow = ["Sr. No.", "User", "PI / Customers", "Calendar Hours"];
    csvData.push(headerRow.join(","));

    // Add the table data
    Object.entries(totalHoursByOwner).forEach(([user, data], index) => {
      const rowData = [
        index + 1,
        user,
        data.advisor ? data.advisor.replace(/,/g, "") : data.advisor, // Remove commas to avoid CSV issues
        data.totalHours.toFixed(2),
      ];
      csvData.push(rowData.join(","));
    });

    // Convert array to CSV string
    const csvString = csvData.join("\n");

    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: "text/csv" });

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "calendar_hours_summary.csv";

    // Append the link to the body and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  const handleEventCreationTypeChange = (value) => {
    setselectedEventCreationUserType(value);
  };

  const handleEventOwnerChangeTypeChange = (value) => {
    setSelectedEventOwnerChangeTypeChange(value);
  };

  return (
    <>
      <Helmet>
        <title>Calendar</title>
      </Helmet>

      <Modal
        isOpen={eventInfoModalisOpen}
        onClose={closeEventInfoModal}
        isCentered
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Event Details</ModalHeader>
            <ModalBody>
              <p>
                User: <b>{selectedEventData.extendedProps.eventOwner}</b>
              </p>
              <p>
                Email: <b>{selectedEventData.extendedProps.emailId}</b>
              </p>
              <p>
                Advisor: <b>{selectedEventData.extendedProps.advisor}</b>
              </p>
              <p>
                Telephone: <b>{selectedEventData.extendedProps.telephone}</b>
              </p>
              <p>
                From:{" "}
                <b>{selectedEventData.extendedProps.formattedStartTime}</b>
              </p>
              <p>
                To: <b>{selectedEventData.extendedProps.formattedEndTime}</b>
              </p>
              <p>
                Instrument: <b>{selectedEventData.extendedProps.instrument}</b>
              </p>
              <p>
                Owner's Comments:{" "}
                <b>{selectedEventData.extendedProps.comments}</b>
              </p>
            </ModalBody>
            <ModalCloseButton />
            <ModalFooter>
              {(userData.UserClass === 1 || userData.UserClass === 2) && (
                <>
                  <Button
                    colorScheme="green"
                    onClick={() => handleChangeEventOwner()}
                  >
                    Change
                    <br />
                    Owner
                  </Button>
                </>
              )}
              {(userData.UserClass === 1 ||
                userData.UserClass === 2 ||
                userName === selectedEventData.extendedProps.eventOwner) && (
                <>
                  <Button
                    colorScheme="orange"
                    ml="5px"
                    onClick={() => handleEditEventComments()}
                  >
                    Edit
                    <br />
                    Comments
                  </Button>
                </>
              )}
              {(userName === selectedEventData.extendedProps.eventOwner ||
                userData.UserClass <= 2 ||
                (userData.UserClass === 3 &&
                  userData.userNamesInPIGroup.some(
                    (item) =>
                      item.UserName ===
                      selectedEventData.extendedProps.eventOwner
                  ))) && (
                <>
                  <Button
                    colorScheme="red"
                    margin="5px"
                    onClick={() => handleDelete()}
                  >
                    Delete
                  </Button>
                </>
              )}
              {userName !== selectedEventData.extendedProps.eventOwner && (
                <>
                  <Button colorScheme="teal" onClick={handleEmailEventOwner}>
                    Email
                  </Button>
                </>
              )}
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      <Modal
        isOpen={deleteConfirmationModalIsOpen}
        onClose={closeDeleteConfirmationModal}
        isCentered
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Confirm Deletion</ModalHeader>
            <ModalBody>
              <p>{deleteConfirmationModalMessage}</p>
              <p>Are you sure you want to Delete this Reservation?</p>
            </ModalBody>
            <ModalCloseButton />
            <ModalFooter
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                colorScheme="red"
                margin="5px"
                onClick={handleFinalDelete}
              >
                Delete
              </Button>
              <Button colorScheme="teal" onClick={closeDeleteConfirmationModal}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      <Modal
        isOpen={changeEventOwnerModalIsOpen}
        onClose={closeChangeEventOwnerModal}
        isCentered
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Change Owner of Event</ModalHeader>
            <ModalBody>
              {userData.UserClass < 3 && (
                <FormControl>
                  <FormLabel>Choose a new owner:</FormLabel>
                  <RadioGroup
                    onChange={handleEventOwnerChangeTypeChange}
                    mb={3}
                  >
                    <Stack direction="row">
                      <Radio value="user">User</Radio>
                      <Radio value="advisor">PI/Advisor</Radio>
                    </Stack>
                  </RadioGroup>
                  <Select
                    onChange={(event) => setNewEventOwner(event.target.value)}
                  >
                    <option value={newEventOwner}>{newEventOwner}</option>
                    {selectedEventOwnerChangeTypeChange === "user" ? (
                      <>
                        {userData.allUserNames.map((user) => (
                          <option key={user.UserName} value={user.UserName}>
                            {user.UserName}
                          </option>
                        ))}
                      </>
                    ) : (
                      <>
                        {userData.allAdvisors
                          .sort((a, b) => a.Name.localeCompare(b.Name))
                          .map((advisor) => (
                            <option
                              key={advisor.Customer_ID}
                              value={advisor.Customer_ID}
                            >
                              {advisor.Name}
                            </option>
                          ))}
                      </>
                    )}
                  </Select>
                </FormControl>
              )}
            </ModalBody>
            <ModalCloseButton />
            <ModalFooter
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                colorScheme="green"
                mr="5px"
                onClick={handleEventOwnerChangeConfirmation}
                disabled={eventCreationIsLoading}
              >
                {eventCreationIsLoading ? <Spinner /> : "Create"}
              </Button>
              <Button
                colorScheme="teal"
                ml="5px"
                onClick={closeChangeEventOwnerModal}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      <Modal
        isOpen={editEventCommentsModalIsOpen}
        onClose={closeEditEventCommentsModal}
        isCentered
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Edit Comments</ModalHeader>
            <ModalBody>
              <FormControl>
                <Textarea
                  value={newEventComments}
                  onChange={(event) => setNewEventComments(event.target.value)}
                ></Textarea>
              </FormControl>
            </ModalBody>
            <ModalCloseButton />
            <ModalFooter
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                colorScheme="green"
                mr="5px"
                onClick={handleChangeEventCommentsConfirmation}
                disabled={eventCreationIsLoading}
              >
                {eventCreationIsLoading ? <Spinner /> : "Create"}
              </Button>
              <Button
                colorScheme="teal"
                ml="5px"
                onClick={closeEditEventCommentsModal}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      <Modal
        isOpen={eventCreationConfirmModalisOpen}
        onClose={handleEventCreationCancel}
        isCentered
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Confirm Event Creation</ModalHeader>
            <ModalBody>
              {userData.UserClass <= 2 && (
                <FormControl>
                  <FormLabel>Who are you booking this slot for?</FormLabel>
                  <RadioGroup onChange={handleEventCreationTypeChange} mb={3}>
                    <Stack direction="row">
                      <Radio value="user">User</Radio>
                      <Radio value="advisor">PI/Advisor</Radio>
                    </Stack>
                  </RadioGroup>
                  <Select onChange={handleEventCreationOwnerUserName}>
                    {selectedEventCreationUserType === "user" ? (
                      <>
                        <option value={userName}>{userName}</option>
                        {userData.allUserNames.map((user) => (
                          <option key={user.UserName} value={user.UserName}>
                            {user.UserName}
                          </option>
                        ))}
                      </>
                    ) : (
                      <>
                        {userData.allAdvisors
                          .sort((a, b) => a.Name.localeCompare(b.Name))
                          .map((advisor) => (
                            <option
                              key={advisor.Customer_ID}
                              value={advisor.Customer_ID}
                            >
                              {advisor.Name}
                            </option>
                          ))}
                      </>
                    )}
                  </Select>
                </FormControl>
              )}
              {userData.UserClass === 3 && (
                <FormControl>
                  <FormLabel>
                    Who are you booking this slot for? (You can book for anyone
                    in your group)
                  </FormLabel>
                  <Select onChange={handleEventCreationOwnerUserName}>
                    <option value={userName}>{userName}</option>
                    {userData.userNamesInPIGroup.map((user) => (
                      <option key={user.UserName} value={user.UserName}>
                        {user.UserName}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControl mt={5}>
                <FormLabel>Comments:</FormLabel>
                <Textarea onChange={handleEventComments}></Textarea>
              </FormControl>
            </ModalBody>
            <ModalCloseButton />
            <ModalFooter
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                colorScheme="green"
                mr="5px"
                onClick={handleEventCreationConfirmation}
                disabled={eventCreationIsLoading}
              >
                {eventCreationIsLoading ? <Spinner /> : "Create"}
              </Button>
              <Button
                colorScheme="teal"
                ml="5px"
                onClick={handleEventCreationCancel}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      <Modal
        isOpen={cancellationRequestEmailModalIsOpen}
        onClose={closeCancellationRequestionEmailModal}
        isCentered
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Request Cancellation Permission</ModalHeader>
            <ModalBody>
              <p>
                You require permission to delete the reservation within 24 hours
                of the reservation start time. To get the permission email the
                CEMMA authority.
              </p>
              <br />
              <FormControl>
                <FormLabel>From: </FormLabel>
                <Input
                  readOnly
                  variant="filled"
                  disabled
                  value={userData.Email}
                />
              </FormControl>
              <FormControl mt="4">
                <FormLabel html>Subject:</FormLabel>
                <Input
                  defaultValue={cancellationRequestEmailSubject}
                  onChange={(e) =>
                    setCancellationRequestEmailSubject(e.target.value)
                  }
                />
              </FormControl>
              <FormControl mt="4">
                <FormLabel>Body:</FormLabel>
                <Textarea
                  defaultValue={cancellationRequestEmailBody}
                  onChange={(e) =>
                    setCancellationRequestEmailBody(e.target.value)
                  }
                />
              </FormControl>
            </ModalBody>
            <ModalCloseButton />
            <ModalFooter
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                colorScheme="blue"
                margin="5px"
                onClick={handleSendCancellationRequestEmail}
              >
                Send Email
              </Button>
              <Button
                colorScheme="teal"
                onClick={closeCancellationRequestionEmailModal}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      <Modal
        isOpen={emailEventOwnersModalIsOpen}
        onClose={closeEmailAllEventOwnersModal}
        isCentered
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Email Customers</ModalHeader>
            <ModalBody>
              <FormControl>
                <FormLabel>From: </FormLabel>
                <Input
                  readOnly
                  variant="filled"
                  disabled
                  value={userData.Email}
                />
              </FormControl>
              <FormControl mt="4">
                <FormLabel>To:</FormLabel>
                <Input
                  readOnly
                  variant="filled"
                  disabled
                  value={emailEventOwnersEmails}
                />
              </FormControl>
              <FormControl mt="4">
                <FormLabel html>Subject:</FormLabel>
                <Input
                  defaultValue={emailEventOwnersEmailSubject}
                  onChange={(e) =>
                    setEmailEventOwnersEmailSubject(e.target.value)
                  }
                />
              </FormControl>
              <FormControl mt="4">
                <FormLabel>Body:</FormLabel>
                <Textarea
                  defaultValue={emailEventOwnersEmailBody}
                  onChange={(e) => setEmailEventOwnersEmailBody(e.target.value)}
                />
              </FormControl>
            </ModalBody>
            <ModalCloseButton />
            <ModalFooter
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                colorScheme="blue"
                margin="5px"
                onClick={handleSendEmailToEventOwners}
              >
                Send Email
              </Button>
              <Button
                colorScheme="teal"
                onClick={closeEmailAllEventOwnersModal}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      <Modal
        isOpen={multipleEventCreationModalIsOpen}
        onClose={handleMultipleEventCreationCancel}
        isCentered
        size="3xl"
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Add Reservations</ModalHeader>
            <ModalBody>
              <FormControl>
                <FormLabel>Select a Date Range</FormLabel>
                <Box bg={theme.colors.white} rounded="md">
                  <RangeDatepicker
                    selectedDates={selectedMultipleDates}
                    onDateChange={(dates) => setSelectedMultipleDates(dates)}
                    minDate={new Date()}
                    configs={{
                      firstDayOfWeek: 1,
                    }}
                  />
                </Box>
              </FormControl>

              <FormControl mt={5}>
                <FormLabel>Select a Time Range</FormLabel>
                <TimePicker.RangePicker
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  format="HH:mm"
                  minuteStep="30"
                  size="large"
                  defaultValue={selectedMultipleEventTime}
                  value={selectedMultipleEventTime}
                  onChange={(dates) => setSelectedMultipleEventTime(dates)}
                />
              </FormControl>

              {multipleEventAdditionLogData.length > 0 && (
                <TableContainer mt={10} borderWidth="2px" borderRadius="md">
                  <Table variant="striped" colorScheme="teal">
                    <Thead>
                      <Tr>
                        <Th fontSize="xl">Date</Th>
                        <Th fontSize="xl">Instrument</Th>
                        <Th fontSize="xl">Status</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {multipleEventAdditionLogData.map((item) => (
                        <Tr
                          key={`${item.date}-${item.instrument}-${item.status}`}
                        >
                          <Td>{item.date}</Td>
                          <Td>{item.instrument}</Td>
                          <Td
                            style={{
                              color:
                                item.status === "Success" ? "green" : "red",
                            }}
                          >
                            <b>{item.status}</b>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            </ModalBody>
            <ModalCloseButton />
            <ModalFooter
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                colorScheme="green"
                onClick={handleCreateMultipleEvents}
                disabled={multipleEventCreationIsLoading}
              >
                {multipleEventCreationIsLoading ? <Spinner /> : "Create Events"}
              </Button>
              <Button
                colorScheme="red"
                ml="5px"
                onClick={handleMultipleDayEventReset}
              >
                Reset
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      <AlertDialog isOpen={alertIsOpen} onClose={closeAlert} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              textAlign="center"
            >
              {alertTitle}
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            {/* <AlertDialogBody>{alertMessage}</AlertDialogBody> */}
            {alertMessage.length > 0 && (
              <AlertDialogBody>
                {alertMessage.map((message, index) => (
                  <p key={index}>→ {message}</p>
                ))}
              </AlertDialogBody>
            )}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Container
        maxW="container.lg"
        bg={calendarColor}
        style={{
          paddingTop: "20px",
          paddingLeft: "5px",
          paddingRight: "5px",
          paddingBottom: "20px",
        }}
      >
        <VStack>
          <VStack maxW="container.lg" spacing={2}>
            <Center w="80%">
              <Text></Text>
              <VStack>
                <Heading as="h3" size="xl">
                  {" "}
                  {instrument === "" && "Home Page Calendar"}
                  {instrument !== "" && instrument}
                </Heading>
              </VStack>
            </Center>

            <Divider w="80%" />

            <FormControl>
              <FormLabel htmlFor="Instrument">Instrument:</FormLabel>
              <Select
                id="Instrument"
                placeholder="-- Select Instrument --"
                bg={theme.colors.white}
                {...register("Instrument")}
                onChange={handleInstrumentChange}
              >
                {instruments.map((instrument) => (
                  <option
                    key={instrument.instrumentName}
                    value={instrument.instrumentName}
                    data-color={instrument.color}
                    data-maxhoursperweekpeak={instrument.MaxHoursPerWeekPeak}
                    data-maxhoursperweekoffpeak={
                      instrument.MaxHoursPerWeekOffPeak
                    }
                    data-maxhoursperday={instrument.MaxHoursPerDay}
                    data-expirationtime={instrument.ExpirationTime}
                    data-allowaccess={instrument.AllowAccess}
                    data-accesdenied={instrument.AccesDenied}
                    data-permission={instrument.Permission}
                    data-availability={instrument.Availablity}
                    data-lastlogindate={instrument.lastLoginDate}
                    data-extendedreservation={instrument.ExtendedReservation}
                  >
                    {instrument.instrumentName}
                  </option>
                ))}
              </Select>
            </FormControl>

            {!instrumentAvailability && (
              <Heading as="h3" size="lg" color="red">
                ⚠ This instrument is unavailable ⚠
              </Heading>
            )}

            <Container
              bg="white"
              maxW="container.lg"
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
                opacity: instrumentAvailability ? 1 : 0.5,
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
              className={isMobile ? "mobile-calendar" : ""}
            >
              <FullCalendar
                ref={calendarRef}
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  momentTimezonePlugin,
                ]}
                headerToolbar={{
                  left: isMobile
                    ? "prev,today,next"
                    : "prev,today,next,refreshButton,multipleReservationsButton",
                  center: "title",
                  right: isMobile
                    ? ""
                    : "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                customButtons={{
                  refreshButton: {
                    text: <IoMdRefresh />,
                    click: handleRefresh,
                  },
                  multipleReservationsButton: {
                    text: "ER",
                    click: handleMultipleEventCreation,
                  },
                }}
                buttonText={{
                  today: "Today",
                  month: "Month",
                  week: "Week",
                  day: "Day",
                  list: "list",
                }}
                initialView={isMobile ? "timeGridDay" : "timeGridWeek"}
                firstDay="1"
                weekends={true}
                events={events}
                scrollTime={currentHour}
                nowIndicator={true}
                datesSet={handleCalendarViewChange}
                eventBackgroundColor={calendarColor}
                selectable={instrumentAvailability || userData.UserClass <= 2}
                eventChange={handleEventEdit}
                select={handleDateSelect}
                selectAllow={handleSelectAllow}
                selectMirror={true}
                businessHours={peakOffPeak}
                eventClick={handleEventClick}
                contentHeight="auto"
                // changing the timezone is messing with the timings, can look into it in the future
                // timeZone="America/Los_Angeles"
                allDayText="24H"
                aspectRatio={1.35}
                longPressDelay={500}
                selectLongPressDelay={500}
              />
            </Container>
            <Center w="80%">
              <Text></Text>
              <Text fontSize="sm">
                All events are in pacific time. Events will be created in
                pacific time as well.
              </Text>
            </Center>
          </VStack>
        </VStack>
      </Container>
      {userData.UserClass === 1 && (
        <Container maxW="container.lg" paddingTop="20px">
          {totalHoursByOwner === {} ? (
            <Center w="100%">
              <LoadingSpinner />
            </Center>
          ) : (
            Object.keys(totalHoursByOwner).length > 0 && (
              <>
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th colSpan={3}>Calendar Hours Summary</Th>
                    </Tr>
                  </Thead>
                  <Thead>
                    <Th colSpan={2}>From: {calendarRangeStartDate}</Th>
                    <Th colSpan={6}> To: {calendarRangeEndDate}</Th>
                  </Thead>
                  <Thead>
                    <Th colSpan={8}>
                      <Button colorScheme="blue" onClick={convertTableToCSV}>
                        Download CSV
                      </Button>
                    </Th>
                  </Thead>
                </Table>
                {isMobile ? (
                  Object.entries(totalHoursByOwner).map(
                    ([user, data], index) => (
                      <Box
                        key={index}
                        mb={4}
                        p={4}
                        borderWidth="1px"
                        borderRadius="md"
                        colSpan={2}
                      >
                        <Text>
                          <strong>SR No:</strong> {index + 1}
                        </Text>
                        <Text>
                          <strong>User:</strong> {user}
                        </Text>
                        <Text>
                          <strong>PI:</strong> {data.advisor}
                        </Text>
                        <Text>
                          <strong>Calendar Hours:</strong>{" "}
                          {formatHours(data.totalHours.toFixed(2))}
                        </Text>
                      </Box>
                    )
                  )
                ) : (
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>SR NO</Th>
                        <Th>User</Th>
                        <Th>PI</Th>
                        <Th>Calendar Hours</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {Object.entries(totalHoursByOwner).map(
                        ([user, data], index) => (
                          <Tr key={user}>
                            <Td>{index + 1}</Td>
                            <Td>{user}</Td>
                            <Td>{data.advisor}</Td>
                            <Td>{formatHours(data.totalHours.toFixed(2))}</Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                )}
              </>
            )
          )}
        </Container>
      )}
    </>
  );
}
